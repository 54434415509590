<div class="flex grow gap-l">
    <div class="flex-column gap-xl">
        <ng-container *ngIf="dashlet.originalData.length; else chartNoDataTemplate">
            <ng-container *ngTemplateOutlet="GraphsSection"> </ng-container>
        </ng-container>
        <ng-template #chartNoDataTemplate>
            <ng-container *ngTemplateOutlet="loadingTemplates"> </ng-container>
        </ng-template>
        <div><ng-container *ngTemplateOutlet="ResponseBreakdown"> </ng-container></div>
    </div>
    <ng-container *ngIf="hugeLayout || (showAi$ | async)">
        <ng-container *ngTemplateOutlet="AiInfoSection"> </ng-container>
    </ng-container>
</div>

<ng-template #loadingTemplates>
    <div *ngIf="sipSummaryLoading" class="loading flex-column center">
        <ng-container *ngTemplateOutlet="vsmLoadingTemplate"> </ng-container>
    </div>
    <div *ngIf="dashlet.originalData.length === 0 && !sipSummaryLoading" class="loading flex-column center">
        <ng-container *ngTemplateOutlet="noDataTemplate"> </ng-container>
    </div>
</ng-template>

<ng-template #GraphsSection>
    <ng-container *ngIf="sipSummaryLoading || dashlet.originalData.length === 0; else showGraphSectionTemplate">
        <ng-container *ngTemplateOutlet="loadingTemplates"> </ng-container>
    </ng-container>
    <ng-template #showGraphSectionTemplate>
        <div class="flex-column gap-m loading" [hidden]="sipSummaryLoading || dashlet.originalData.length === 0">
            <dashlet-line-graph #sipNetworkChart [data]="lineData" [aspect]="3" [options]="sipChartOptions" [width]="'37.25rem'" [height]="'250px'"></dashlet-line-graph>
            <label class="mat-caption" id="slider-group-label">Show</label>
            <div aria-labelledby="slider-group-label" class="grid-3-columns toggle-grid gap-m">
                <mat-slide-toggle
                    [(ngModel)]="slider.selected"
                    (ngModelChange)="buttonClicked(slider)"
                    *ngFor="let slider of graphButtons"
                    [matTooltip]="getMessageButtonTooltip(slider)"
                    color="primary"
                    >{{ slider.label }}</mat-slide-toggle
                >
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #ResponseBreakdown>
    <div class="flex-column grow gap-m" *ngIf="!smallLayout()">
        <dashlet-section-header> 24 Hour Summary </dashlet-section-header>
        <label class="mat-caption" id="radio-group-label">Group By</label>
        <mat-radio-group aria-labelledby="radio-group-label" class="flex gap-l" color="primary" [(ngModel)]="showTable" (ngModelChange)="changeTable()">
            <mat-radio-button *ngFor="let b of tableButtons" [value]="b.label">
                {{ b.label }}
            </mat-radio-button>
        </mat-radio-group>
        <ng-container *ngIf="showResponseTable; else messageTableTemplate">
            <dashlet-table
                [columns]="responseTableColumns"
                [dataSource]="dashlet.summaryData"
                [sortable]="false"
                [rowExpansionColumns]="responseExpansionColumns"
                [tableHeightRows]="7"
                rowExpansionField="expansion"
            ></dashlet-table>
        </ng-container>
        <ng-template #messageTableTemplate>
            <dashlet-table
                [columns]="messageTableColumns"
                [dataSource]="dashlet.messageData"
                [sortable]="false"
                [rowExpansionColumns]="responseExpansionColumns"
                [tableHeightRows]="7"
                rowExpansionField="expansion"
            ></dashlet-table>
        </ng-template>
    </div>
</ng-template>

<ng-template #AiInfoSection>
    <div class="flex-column grow gap-l" *ngIf="dashlet.graphData4xx.multiEquipData.length > 0">
        <mat-accordion>
            <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header> <mat-panel-title>4XX Anomalies </mat-panel-title></mat-expansion-panel-header
                ><dashlet-table
                    [columns]="aiColumns"
                    [dataSource]="dashlet.tempAiData4xx"
                    [rowExpansionColumns]="aiExpansionColumns"
                    [tableHeightRows]="14"
                    rowExpansionField="chartData"
                    [rowExpansionHeight]="11"
                ></dashlet-table>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header> <mat-panel-title>5XX Anomalies </mat-panel-title></mat-expansion-panel-header
                ><dashlet-table
                    [columns]="aiColumns"
                    [dataSource]="dashlet.tempAiData5xx"
                    [rowExpansionColumns]="aiExpansionColumns"
                    [tableHeightRows]="14"
                    rowExpansionField="chartData"
                    [rowExpansionHeight]="11"
                ></dashlet-table>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header> <mat-panel-title>6XX Anomalies </mat-panel-title></mat-expansion-panel-header
                ><dashlet-table
                    [columns]="aiColumns"
                    [dataSource]="dashlet.tempAiData6xx"
                    [rowExpansionColumns]="aiExpansionColumns"
                    [tableHeightRows]="14"
                    rowExpansionField="chartData"
                    [rowExpansionHeight]="11"
                ></dashlet-table>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div *ngIf="dashlet.graphData4xx.multiEquipData.length === 0 && dashlet.loadingAIData" class="loading center">
        <ng-container *ngTemplateOutlet="vsmLoadingTemplate"> </ng-container>
    </div>

    <div *ngIf="dashlet.graphData4xx.multiEquipData.length === 0 && !dashlet.loadingAIData" class="loading center">
        <ng-container *ngTemplateOutlet="noDataTemplateAI"> </ng-container>
    </div>
</ng-template>

<!-- loading template -->
<ng-template #vsmLoadingTemplate>
    <vsm-dashlet-loading></vsm-dashlet-loading>
</ng-template>

<!-- no data template -->
<ng-template #noDataTemplate>
    <div class="flex-column gap-m align-center">
        <div class="mat-body-1 text-center">
            No SIP data found. Check your Session Managers or Session Border Controllers are correctly configured to upload Syslog data.
        </div>
        <button mat-raised-button color="primary" (click)="openSipDocumentation()">Read More</button>
    </div>
</ng-template>

<!-- no data template -->
<ng-template #noDataTemplateAI>
    <div class="dashlet-no-data-text">
        No AI anomaly data for SIP traces found. Check your Session Managers or Session Border Controllers are correctly configured to upload Syslog data. AI
        analysis requires one month of SIP data for a given equipment.
    </div>
</ng-template>
