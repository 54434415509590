import * as Actions from '@actions/index';
import { AppState, selectDataFromCommonEntity, selectEntity } from '@reducers/index';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    ChartCell,
    ColumnTypes,
    CustomerLogo,
    DashletSIPResponseSummary,
    LineGraphData,
    SeverityRatioBar,
    SipMessageData,
    SipSummaryData
} from '@models/index';
import { DashletLineGraphComponent } from '../dashlet-line-graph/dashlet-line-graph.component';
import { DashletService } from '@services/index';
import { select, Store } from '@ngrx/store';
import { UIChart } from 'primeng/chart';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
    selector: 'dashlet-sip-response-summary',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'dashlet-sip-response-summary.component.html',
    styleUrls: ['dashlet-sip-response-summary.component.scss']
})
export class DashletSipResponseSummaryComponent implements OnInit, OnDestroy {
    @Input() dashlet: DashletSIPResponseSummary;
    @ViewChild('sipChart') sipChart: DashletLineGraphComponent;
    @ViewChild('sipChartAI') sipChartAI: DashletLineGraphComponent;

    private pSipNetworkChart: DashletLineGraphComponent;

    get sipNetworkChart() {
        return this.pSipNetworkChart;
    }
    @ViewChild('sipNetworkChart') set sipNetworkChart(value: DashletLineGraphComponent) {
        this.pSipNetworkChart = value;
        this.updateChart();
    }
    private destroy$ = new Subject<void>();
    public graphButtons = [
        { label: 'Registration', selected: true },
        { label: 'Invites', selected: true },
        { label: 'Service Messages', selected: true },
        { label: 'Informational', selected: true },
        { label: 'Other', selected: true },
        { label: 'Bye', selected: true }
    ];

    public tableButtons = [
        { label: 'Response Code', selected: true },
        { label: 'Message Type', selected: false }
    ];

    public showResponseTable = true;
    public showTable: string = 'Response Code';

    public sipSummaryLoading = false;

    public multiEquipAiData: any[] = [];
    public tempAiData: any[] = [];
    public aiToolTipEquipmentName: string = 'Equipment requires one month of steady SIP data to produce AI analysis';

    private subscription: Subscription = new Subscription();

    public responseTableColumns: ColumnTypes[] = [
        { columnDef: 'expand', header: '' },
        {
            header: 'Response',
            columnDef: 'response',
            cell: (row: SipSummaryData) => row.response
        },
        {
            header: 'Count',
            columnDef: 'sessions',
            cell: (row: SipSummaryData) => row.sessions,
            type: 'numeric'
        },
        {
            header: 'Percentage',
            columnDef: 'percentage',
            cell: (row: SipSummaryData) => `${row.percentage}%`,
            type: 'numeric'
        },
        {
            header: 'Distribution',
            columnDef: 'distribution',
            cell: (row: SipSummaryData): SeverityRatioBar[] => {
                return [
                    {
                        value: row.distribution.first.value,
                        colour: row.distribution.first.colour,
                        tooltip: row.distribution.first.title
                    },
                    {
                        value: row.distribution.second.value,
                        colour: row.distribution.second.colour,
                        tooltip: row.distribution.second.title
                    },
                    {
                        value: row.distribution.other.value,
                        colour: row.distribution.other.colour,
                        tooltip: row.distribution.other.title
                    }
                ];
            },
            type: 'ratio'
        }
    ];

    public responseExpansionColumns: ColumnTypes[] = [
        {
            header: 'Response',
            columnDef: 'response',
            cell: (row: SipSummaryData) => row.response
        },
        {
            header: 'Count',
            columnDef: 'sessions',
            cell: (row: SipSummaryData) => row.sessions,
            type: 'numeric'
        },
        {
            header: 'Percentage',
            columnDef: 'percentage',
            cell: (row: SipSummaryData) => `${row.percentage}%`,
            type: 'numeric'
        }
    ];

    public messageTableColumns: any[] = [
        { columnDef: 'expand', header: '' },
        {
            header: 'Message',
            columnDef: 'message',
            cell: (row: SipMessageData) => row.message
        },
        {
            header: 'Total',
            columnDef: 'total',
            cell: (row: SipMessageData) => row.total,
            type: 'numeric'
        },
        {
            header: 'Percentage',
            columnDef: 'percentage',
            cell: (row: SipMessageData) => `${row.percentage}%`,
            type: 'numeric'
        },
        {
            header: 'Distribution',
            columnDef: 'distribution',
            cell: (row: SipMessageData): SeverityRatioBar[] => {
                return row.distribution.map(distribution => {
                    return {
                        value: distribution.value,
                        colour: distribution.colour,
                        tooltip: distribution.title
                    };
                });
            },
            type: 'ratio'
        }
    ];

    public aiColumns: ColumnTypes[] = [
        { columnDef: 'expand', header: '' },
        {
            header: 'Anomalies Detected',
            columnDef: 'anomaliesDetected',
            cell: (row: any) => row.anomaliesDetected
        },
        {
            header: 'Equipment Name',
            columnDef: 'equipmentName',
            cell: (row: any) => row.equipmentName,
            headerTooltip: this.aiToolTipEquipmentName
        }
    ];

    public aiExpansionColumns: ColumnTypes[] = [
        {
            header: '',
            columnDef: 'chartData',
            cell: (row: any): ChartCell => {
                return {
                    options: this.sipChartOptionsAi,
                    chartHeight: '300px',
                    data: row
                };
            },
            type: 'chart'
        }
    ];

    private green = {
        label: '2XX/3XX',
        backgroundColor: this.dashletService.getChartColors().green,
        borderColor: this.dashletService.getChartColors().green,
        pointBackgroundColor: this.dashletService.getChartColors().green,
        pointRadius: 0,
        pointHitRadius: 10,
        data: [],
        fill: true
    };
    private blue = {
        label: '1XX',
        backgroundColor: this.dashletService.getChartColors().blue,
        borderColor: this.dashletService.getChartColors().blue,
        pointBackgroundColor: this.dashletService.getChartColors().blue,
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };
    public amber = {
        label: '4XX',
        backgroundColor: this.dashletService.getChartColors().amber,
        borderColor: this.dashletService.getChartColors().amber,
        pointBackgroundColor: this.dashletService.getChartColors().amber,
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };

    private red = {
        label: '5XX',
        backgroundColor: this.dashletService.getChartColors().red,
        borderColor: this.dashletService.getChartColors().red,
        pointBackgroundColor: this.dashletService.getChartColors().red,
        pointRadius: 0,
        pointHitRadius: 10,
        fill: true,
        data: []
    };
    businessPartnerColor: string;
    showAi$: Observable<boolean>;
    constructor(
        private dashletService: DashletService,
        private store$: Store<AppState>,
        private cdr: ChangeDetectorRef
    ) {}
    public sipChartOptions: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                max: null,
                min: null,
                time: {
                    unit: 'hour',
                    displayFormats: {
                        day: 'HH'
                    },
                    stepSize: 3
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0
                }
            },
            y: {
                type: 'linear',
                beginAtZero: true,
                stacked: true,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false,
                    precision: 0
                }
            }
        },
        animations: {
            duration: 0
        },
        plugins: {
            tooltip: {
                displayColors: true,
                callbacks: {
                    title: context => {
                        const date = new Date(context[0].raw.x);
                        const roundedDate = new Date(date.setMinutes(0, 0, 0)); // Round to the nearest hour
                        const hours = roundedDate.getHours();
                        const minutes = roundedDate.getMinutes();
                        const ampm = hours >= 12 ? 'PM' : 'AM';
                        const roundedTime = `${hours % 12 || 12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
                        return `${roundedTime}`;
                    }
                }
            },
            legend: {
                display: false,
                position: 'bottom'
            },
            filler: {
                propagate: true
            }
        }
    };

    public sipChartOptionsAi: any = {
        scales: {
            x: {
                type: 'time',
                grid: {
                    display: false
                },
                max: null,
                min: null,
                time: {
                    unit: 'hour',
                    displayFormats: {
                        day: 'HH:mm aa'
                    },
                    stepSize: 1
                },
                ticks: {
                    fontSize: 10.2,
                    autoSkip: false
                },
                title: {
                    display: false
                }
            },
            y: {
                type: 'linear',
                stacked: false,
                grid: {
                    display: false
                },
                ticks: {
                    fontSize: 10.2
                },
                min: 0
            }
        },
        animation: {
            duration: 0
        },
        plugins: {
            tooltip: {
                displayColors: false,
                callbacks: {
                    title: tipItem => {
                        const itemList = tipItem[0]?.dataset.data || [];
                        const index = tipItem[0].dataIndex;

                        if (index === -1) {
                            return 'Margin Boundary Point';
                        } else if (JSON.parse(itemList[index].aiDataPoint.IsAnomaly.toLowerCase())) {
                            return 'Anomaly Detected';
                        } else if (!JSON.parse(itemList[index].aiDataPoint.IsAnomaly.toLowerCase())) {
                            return 'No Anomaly Detected';
                        }
                    },
                    label: context => {
                        const itemList = context.dataset.data;
                        const index = context.dataIndex;

                        if (index === -1) {
                            return null;
                        } else {
                            const resp = [];
                            resp.push('');
                            resp.push('Actual Value: ' + context.raw.y.toString());
                            resp.push('Expected Value: ' + itemList[index].aiDataPoint.ExpectedValue.toString());
                            resp.push('');
                            resp.push(
                                'Upper Margin: ' +
                                    (
                                        itemList[index].aiDataPoint.UpperMargin +
                                        itemList[index].aiDataPoint.ExpectedValue
                                    ).toString() +
                                    ' \tLower Margin: ' +
                                    (
                                        itemList[index].aiDataPoint.ExpectedValue -
                                        itemList[index].aiDataPoint.LowerMargin
                                    ).toString()
                            );
                            resp.push('');
                            resp.push('Compared against 4 week pattern of existing data');
                            resp.push(
                                itemList[index].aiDataPoint.UTCDateTime.toLocaleDateString() +
                                    ' ' +
                                    itemList[index].aiDataPoint.UTCDateTime.toLocaleTimeString()
                            );
                            return resp;
                        }
                    }
                },
                filter: tooltipItem => {
                    const index = tooltipItem.dataset.data.findIndex(item => item['x'] === tooltipItem.raw.x);
                    return tooltipItem.datasetIndex === 1 && tooltipItem.dataIndex === index;
                }
            },
            legend: {
                display: true,
                labels: {
                    usePointStyle: true
                },
                position: 'top'
            }
        }
    };

    public lineData: any = {};
    public lineDataAI: { datasets: any[] } = { datasets: [] };

    public smallLayout(): boolean {
        return this.dashlet.getSize().id === 1;
    }

    get hugeLayout(): boolean {
        return this.dashlet.getSize().id === 2;
    }

    public ngOnInit(): void {
        this.onLoad();

        this.dashlet.settingsUpdated.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.dashlet.resetData();
            this.green.data = [];
            this.blue.data = [];
            this.amber.data = [];
            this.red.data = [];
            this.lineData = {
                datasets: [this.amber, this.red, this.blue, this.green]
            };
            this.dashlet.loadingAIData = false;
            this.dashlet.logo = new CustomerLogo(null, null);
            this.onLoad();
        });
    }

    private getEntityLogo(): void {
        if (this.dashlet.configured) {
            this.store$.dispatch(Actions.GetEntityLogo({ entityId: this.dashlet.customer.customerId }));

            this.subscription.add(
                this.store$.pipe(select(selectEntity(this.dashlet.customer.customerId))).subscribe(logo => {
                    if (logo) {
                        this.dashlet.logo = new CustomerLogo(logo.image, logo.imageType);
                    }
                })
            );
        }
    }

    public onLoad(): void {
        this.showAi$ = this.dashlet.showAi;
        this.dashlet.showAi.next(this.hugeLayout);
        this.getEntityLogo();
        if (this.dashlet.equipmentId && this.dashlet.equipmentId.length) {
            this.dashlet.equipmentId.forEach(equipment => {
                this.store$.dispatch(
                    Actions.SubscribeToRealTimeService({
                        equipmentId: equipment.value,
                        command: this.dashlet.ONE_HOUR_SUMMARY_COMMAND_TYPE_ID
                    })
                );
                this.store$.dispatch(
                    Actions.GetCommonHistoric({
                        equipmentId: equipment.value,
                        commandTypeId: this.dashlet.ONE_HOUR_SUMMARY_COMMAND_TYPE_ID,
                        from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                        to: new Date().toISOString(),
                        max: this.dashlet.hourSetting
                    })
                );
                this.store$
                    .pipe(
                        select(
                            selectDataFromCommonEntity(equipment.value + this.dashlet.ONE_HOUR_SUMMARY_COMMAND_TYPE_ID)
                        ),
                        takeUntil(this.destroy$)
                    )
                    .subscribe(data => {
                        if (data) {
                            data.forEach(dataRow => {
                                let newDate = new Date(dataRow.timestamp);
                                this.dashlet.lastUpdated =
                                    newDate > new Date(this.dashlet.lastUpdated) ? newDate : this.dashlet.lastUpdated;
                                    this.dashlet.refreshNameTag();
                                if (dataRow) {
                                    if (!this.dashlet.checkDataIfProcessed(dataRow.id, dataRow.timestamp)) {
                                        this.dashlet.originalData.push(dataRow);
                                        this.dashlet.originalData.sort((a, b) => {
                                            let aDate = new Date(a.timestamp);
                                            let bDate = new Date(b.timestamp);
                                            return aDate > bDate ? 1 : -1;
                                        });
                                    }
                                }
                            });
                            this.dashlet.processRTGDataArray(data);
                        }
                        this.cdr.detectChanges();
                    });
                this.subForSingleEquip(equipment.value, this.dashlet.AZURE_AI_ANOMALY_LAST_DETECT_4XX_COMMAND_TYPE_ID);
                this.subForSingleEquip(equipment.value, this.dashlet.AZURE_AI_ANOMALY_LAST_DETECT_5XX_COMMAND_TYPE_ID);
                this.subForSingleEquip(equipment.value, this.dashlet.AZURE_AI_ANOMALY_LAST_DETECT_6XX_COMMAND_TYPE_ID);
            });
            this.subscription.add(
                this.dashlet.onChartDataUpdated.subscribe(() => {
                    this.updateChart();
                })
            );
            this.subscription.add(
                this.dashlet.sipDataUpdated.subscribe(() => {
                    this.updateChart();
                    this.sipSummaryLoading = false;
                })
            );
            this.subscription.add(
                this.dashlet.sipDataUpdating.subscribe(() => {
                    this.updateChart();
                    this.sipSummaryLoading = true;
                })
            );
        }
    }
    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscription.unsubscribe();
    }

    private subForSingleEquip(equipmentId: string, commandTypeID: string): void {
        this.store$.dispatch(Actions.SubscribeToRealTimeService({ equipmentId: equipmentId, command: commandTypeID }));
        this.store$.dispatch(
            Actions.GetCommonHistoric({
                equipmentId: equipmentId,
                commandTypeId: commandTypeID,
                from: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
                to: new Date().toISOString(),
                max: this.dashlet.hourSetting
            })
        );
        this.subscription.add(
            this.store$.pipe(select(selectDataFromCommonEntity(equipmentId + commandTypeID))).subscribe(
                data => {
                    if (data) {
                        this.dashlet.processAnomalyPointsDataArray(
                            data,
                            this.dashlet.getStorageArray(commandTypeID),
                            this.dashlet.getGraphDataObject(commandTypeID),
                            commandTypeID
                        );
                        this.cdr.detectChanges();
                    }
                },
                error => {
                    this.dashlet.loadingAIData = false;
                }
            )
        );
    }

    public buttonClicked(button: { label: string; selected: boolean }) {
        this.sipSummaryLoading = true;
        let selectedButtons = this.graphButtons.filter(b => b.selected === true).map(e => e.label);

        this.dashlet.changeObservedMessages(selectedButtons);
    }

    public changeTable() {
        this.tableButtons.map(button => (button.selected = !button.selected));
        this.showResponseTable = !this.showResponseTable;
    }

    public checkData(data: any): any {
        return data.chartData;
    }

    public getRowInfo(data: any): any[] {
        return [data];
    }

    public determineSeverity(data: any): any {
        return 'rgb(10, 155, 2)';
    }

    private updateChart() {
        this.green.data = this.removeDuplicates(this.dashlet.graphData.green).sort(
            (a, b) => a.x.getTime() - b.x.getTime()
        );
        this.blue.data = this.removeDuplicates(this.dashlet.graphData.blue).sort(
            (a, b) => a.x.getTime() - b.x.getTime()
        );
        this.amber.data = this.removeDuplicates(this.dashlet.graphData.amber).sort(
            (a, b) => a.x.getTime() - b.x.getTime()
        );
        this.red.data = this.removeDuplicates(this.dashlet.graphData.red).sort((a, b) => a.x.getTime() - b.x.getTime());
        this.lineData = {
            datasets: [this.amber, this.red, this.blue, this.green]
        };
        if (this.sipNetworkChart) {
            this.sipNetworkChart.updateChart();
        }

        if (this.sipChartAI !== undefined) {
            this.sipChartAI.updateChart();
        }
    }

    private removeDuplicates(data): any {
        let seen = {};
        return data.filter(item => {
            let key = `${item.x}-${item.y}`;
            if (seen[key]) {
                return false;
            }
            seen[key] = true;
            return true;
        });
    }

    public openSipDocumentation(): void {
        window.open(`${environment.documentationServerUrl}wiki/spaces/VSMDOC/pages/30280394/SIP+Response+Summary+dashlets`, '_blank');
    }

    getMessageButtonTooltip(button: { label: string; selected: boolean }) {
        switch (button.label) {
            case 'Registration':
                return 'REGISTER';
            case 'Invites':
                return 'INVITE\nACK\nPRACK\nCANCEL';
            case 'Service Messages':
                return 'PUBLISH\nNOTIFY\nSUBSCRIBE';
            case 'Informational':
                return 'OPTIONS\nINFO';
            case 'Other':
                return 'MESSAGE\nREFER\nUPDATE';
            case 'Bye':
                return 'BYE';
            default:
                return '';
        }
    }
}
